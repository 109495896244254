export interface CredScanPattern {
  name: string;
  regex: RegExp;
}

export function getCredRegexPatterns() {
  return [
    // API Keys
    { name: 'Generic API Key', regex: /[a-zA-Z0-9]{32,}/ },
    { name: 'AWS Access Key', regex: /AKIA[0-9A-Z]{16}/ },
    { name: 'Google API Key', regex: /AIza[0-9A-Za-z\-_]{35}/ },
    { name: 'Stripe API Key', regex: /sk_live_[0-9a-zA-Z]{24}/ },

    // Passwords
    { name: 'Password', regex: /(password|passwd|pwd|secret|key)\s*[:=]\s*['"]?([a-zA-Z0-9@#$%^&*!]{8,})['"]?/i },

    // Private Keys
    { name: 'Private Key', regex: /\s*(\bBEGIN\b).*(PRIVATE KEY\b)\s*/gm },

    // OAuth Tokens
    { name: 'OAuth Token', regex: /[a-zA-Z0-9_-]{20,}/ },

    // Database Connection Strings
    { name: 'Database Connection String', regex: /(server|host|data source|initial catalog|user id|uid|password|pwd)=([^;]+)/i },

    // JWT Tokens
    { name: 'JWT Token', regex: /eyJ[a-zA-Z0-9_-]+\.[a-zA-Z0-9_-]+\.[a-zA-Z0-9_-]+/ },

    // Cloud Storage Keys
    { name: 'AWS Secret Access Key', regex: /[a-zA-Z0-9/+=]{40}/ },
    { name: 'Azure Shared Access Signature', regex: /sv=[0-9]{4}-[0-9]{2}-[0-9]{2}&se=[^&]+&sig=[a-zA-Z0-9%]+/ },

    // Microsoft Azure Keys
    {
      name: 'Azure Generic Key',
      regex: /(azure|connection|endpoint|access|secret|shared|sas|key|token)[\s:=]+['"]?([a-zA-Z0-9+/=._-]{8,})['"]?/i
    },
    {
      name: 'Azure Storage Account Connection String',
      regex: /DefaultEndpointsProtocol=https;AccountName=[a-z0-9-]+;AccountKey=[a-zA-Z0-9+/=]+;EndpointSuffix=core.windows.net/
    },
    { name: 'Azure Shared Access Signature (Detailed)', regex: /SharedAccessSignature=sv=[^&]+&sig=[a-zA-Z0-9%]+/ },

    // Encryption Keys
    { name: 'Base64 Encryption Key', regex: /[a-zA-Z0-9+/=]{40,}/ },

    // Generic Secrets
    { name: 'Generic Secret', regex: /(secret|key|token|password|pwd|access|auth)[\s:=]+['"]?([a-zA-Z0-9+/=._-]{8,})['"]?/i }
  ];
}
