import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { GetEnvConfiguration } from '../services/configurationService';
import { maskTelemetryItem } from '../utils/credMaskUtils';

const env = GetEnvConfiguration();

const browserHistory = createBrowserHistory({ basename: '' });
export const reactPlugin = new ReactPlugin();

export const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: env.APPINSIGHTS_INSTRUMENTATIONKEY,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory }
    }
  }
});

const filterTelemetry = (telemetryItem: ITelemetryItem) => {
  try {
    maskTelemetryItem(telemetryItem);
    return true;
  } catch (error) {
    // We are not filtering exception so no possible infinit recursive calls.
    appInsights.trackException({
      exception: new Error(`Failed to filter telemetry, exception: ${error}, itemtype: ${telemetryItem.baseType}`)
    });
    return true;
  }
};

appInsights.loadAppInsights();
if (!env.TELEMETRY_CRED_FILTER_ENABLED) appInsights.addTelemetryInitializer(filterTelemetry);
