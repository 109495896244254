import { ITelemetryItem } from '@microsoft/applicationinsights-web';
import { getCredRegexPatterns } from './credMaskPatterns';

export const credMask = 'xxx';
const dummyAbsPath = 'https://dummy.com';

export interface MaskResult {
  masked: boolean;
  result: string;
  maskedPatterns: string;
}

export const maskTelemetryItem = (telemetryItem: ITelemetryItem): void => {
  // Page view masking
  if (telemetryItem?.baseType === 'PageviewData') {
    maskPageViewCredentials(telemetryItem);
  }
  // RemoteDependency masking
  else if (telemetryItem?.baseType === 'RemoteDependencyData') {
    maskRemoteDependencyCredentials(telemetryItem);
  }
};

const maskPageViewCredentials = (telemetryItem: ITelemetryItem): void => {
  if (telemetryItem?.baseData?.refUri) {
    telemetryItem.baseData.refUri = maskUrlIfNeeded(telemetryItem.baseData.refUri);
  }
};

const maskRemoteDependencyCredentials = (telemetryItem: ITelemetryItem): void => {
  if (telemetryItem?.baseData?.name) {
    telemetryItem.baseData.name = maskUrlIfNeeded(telemetryItem.baseData.name);
  }
  if (telemetryItem?.baseData?.target) {
    telemetryItem.baseData.target = maskUrlIfNeeded(telemetryItem.baseData.target);
  }
};

export const maskUrlIfNeeded = (url: string): string => {
  try {
    const absUrlRes: URL | null = tryCreateAbsoluteURL(url);
    const urlObj = absUrlRes ? absUrlRes : new URL(url, dummyAbsPath);
    urlObj.searchParams.forEach((value, key) => {
      const maskResult = maskStrIfNeeded(value);
      if (maskResult.masked) {
        urlObj.searchParams.set(key, maskResult.result);
      }
    });
    return absUrlRes ? urlObj.toString() : urlObj.pathname + urlObj.search;
  } catch (error) {
    return url;
  }
};

const tryCreateAbsoluteURL = (url: string): URL | null => {
  try {
    const absUrl = new URL(url);
    return absUrl;
  } catch {
    return null;
  }
};

const maskStrIfNeeded = (message: string): MaskResult => {
  let result = message;
  let masked = false;
  let maskedPatterns = '';
  for (const pattern of getCredRegexPatterns()) {
    const found = pattern.regex.test(result);
    if (found) {
      masked = true;
      result = result.replace(pattern.regex, credMask);
      maskedPatterns = maskedPatterns.concat(pattern.name);
    }
  }
  return { masked, result, maskedPatterns: maskedPatterns };
};
