import { MONITORS_DEPENDENCIES, monitorDependency } from './monitorService';

const configuration = window['config'];

import * as Logger from './loggerService';

const LOGGER_PREFIX = 'Configuration service:';
interface IConfiguration {
  SERVER_BASE_API_URL: string;
  APPINSIGHTS_INSTRUMENTATIONKEY: string;
  BING_MAP_KEY: string;
  DIVERSE_INCLUSION_ENABLED: boolean;
  DIVERSE_INCLUSION_MULTIGEO_ENABLED: boolean;
  TRAINING_DESIGNATIONS_ENABLED: boolean;
  VALID_MESSAGE_ORIGINS: string[];
  TELEMETRY_CRED_FILTER_ENABLED: boolean;
}

export const GetEnvConfiguration = (): IConfiguration => {
  return configuration;
};

let configMonitored = false;
export const monitorConfiguration = () => {
  if (!configMonitored) {
    configMonitored = true;
    // Check config exist
    if (!configuration) {
      // No config - no AppInsights key - no logs - write to console
      console.error('Missing entire config');
    } else {
      const config = configuration as IConfiguration;
      const hasAppInsightsKey = config.APPINSIGHTS_INSTRUMENTATIONKEY ? true : false;
      const hasBingMapsKey = config.BING_MAP_KEY ? true : false;
      const hasServerBaseUrl = config.SERVER_BASE_API_URL ? true : false;
      if (hasAppInsightsKey && hasBingMapsKey && hasServerBaseUrl) {
        monitorDependency({ name: MONITORS_DEPENDENCIES.CONFIG, success: true });
        Logger.Info(LOGGER_PREFIX, 'Config is valid');
      } else {
        // No AppInsights key - no logs - write to console
        if (!hasAppInsightsKey) {
          console.error('Missing app insights config.');
        } else {
          let errorMessage = 'Missing the following config fields:';
          errorMessage = hasBingMapsKey ? errorMessage : errorMessage + ' BingMaps key';
          errorMessage = hasServerBaseUrl ? errorMessage : errorMessage + ' Server base url';
          monitorDependency({ name: MONITORS_DEPENDENCIES.CONFIG, success: false, error: errorMessage });
          Logger.Err(LOGGER_PREFIX, errorMessage);
        }
      }
    }
  }
};
